import { Nullable } from '@/core/domain/type/types'
import AbstractBaseEntity from '@/core/domain/model/AbstractBaseEntity'

export class RuleTreeElementDTO {
  id?: Nullable<number>
  guid?: Nullable<string>
  name?: Nullable<string>
  parentId?: Nullable<number>
  ruleId?: Nullable<number>
  groupId?: Nullable<number>
  elementType: string
  rowOrder: number

  constructor ({ id, guid, name, parentId, ruleId, groupId, elementType, rowOrder }) {
    this.id = id
    this.guid = guid
    this.name = name
    this.parentId = parentId
    this.ruleId = ruleId
    this.groupId = groupId
    this.elementType = elementType
    this.rowOrder = rowOrder
  }
}

export default class RuleTreeElement extends AbstractBaseEntity {
  private id?: Nullable<number>
  private guid?: Nullable<string>
  private name: string
  private parentId: Nullable<number>
  private ruleId: Nullable<number>
  private groupId: Nullable<number>
  private elementType: string
  private rowOrder: number

  constructor (
    id: Nullable<number>,
    guid: Nullable<string>,
    name: string,
    parentId: Nullable<number>,
    ruleId: Nullable<number>,
    groupId: Nullable<number>,
    elementType: string,
    rowOrder: number
  ) {
    super()
    this.id = id
    this.guid = guid
    this.name = name
    this.parentId = parentId
    this.ruleId = ruleId
    this.groupId = groupId
    this.elementType = elementType
    this.rowOrder = rowOrder
  }

  static create (dto: RuleTreeElementDTO): RuleTreeElement {
    return new RuleTreeElement(
      dto.id,
      dto.guid,
      dto.name,
      dto.parentId,
      dto.ruleId,
      dto.groupId,
      dto.elementType,
      dto.rowOrder
    )
  }

  setId (id: number): void {
    this.id = id
  }

  getId () {
    return this.id
  }

  setGuid (guid: string): void {
    this.guid = guid
  }

  getGuid (): string {
    return this.guid
  }

  setName (name: string): void {
    this.name = name
  }

  getName (): string {
    return this.name
  }

  setParentId (parentId: Nullable<number>): void {
    this.parentId = parentId
  }

  getParentId (): Nullable<number> {
    return this.parentId
  }

  setRuleId (ruleId: Nullable<number>): void {
    this.ruleId = ruleId
  }

  getRuleId (): Nullable<number> {
    return this.ruleId
  }

  setGroupId (groupId: Nullable<number>): void {
    this.groupId = groupId
  }

  getGroupId (): Nullable<number> {
    return this.groupId
  }

  setElementType (elementType: string): void {
    this.elementType = elementType
  }

  getElementType (): string {
    return this.elementType
  }

  setRowOrder (rowOrder: number): void {
    this.rowOrder = rowOrder
  }

  getRowOrder (): number {
    return this.rowOrder
  }
}
